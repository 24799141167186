<template>
  <E2ZoneDessin ref="dessin" :width="640" :height="430" :readonly="e2_countdown === null || e2_countdown === 0"/>
</template>

<script>
export default {
  name: 'Etat2AttenteDessin',

  data() {
    return {
    };
  },

  methods: {
    countdownFinished() {
      this.$refs.dessin.countdownFinished(() => {
        this.$emit('dessinFini', this.$refs.dessin.toByteArray());
      });
    },

    exportData() {
      return this.$refs.dessin.exportData();
    },

    importData(data) {
      this.waitForRefDessin(10, 100, () => {
        this.$refs.dessin.importData(data);
      });
    },

    waitForRefDessin(retries, delay, callback) {
      if ('dessin' in this.$refs && this.$refs.dessin !== null && this.$refs.dessin !== undefined) {
        callback();
      } else {
        if (retries > 0) {
          setTimeout(() => {
            this.waitForRefDessin(retries-1, delay, callback);
          }, delay);
        } else {
          console.warn('excedded retries on waitForRefDessin')
        }
      }
    },
  },

  props: ['e2_countdown'],

  components:{
    E2ZoneDessin: () => import('../E2ZoneDessin'),
  },
}
</script>
